<template>
  <div>
    <el-row  class="background_img">
      <div class="background_img_mask"></div>
      <h1 style="text-align: center;height: 100%;line-height: 12rem;margin: 0px;color: #F3F4F6;
      letter-spacing: .8em;z-index: 111;
      ">提出你的问题</h1>
    </el-row>
    <CardBackground>
    <el-row v-loading="loading"
    >
      <el-col :md="{span:5}" style="margin-right: 15px;margin-left: 15px;">
        <div >
          <Hot type="hotQuiz" style="margin-bottom: 15px;"></Hot>
          <Hot type="hotBlog" style="margin-bottom: 15px;"></Hot>
        </div>
      </el-col>
      <el-col :md="{span:18}" style="">
        <div style="background-color: #FFFFFF;border-radius: 3px;">
          <span style="font-size: 20px;width: 9%;background-color: #ffffff;height: 40px;display: inline-block;
          text-align: center;line-height: 40px;border: none;border-radius: 3px;
          float: left;color: #333333;"><strong>标题</strong></span>
          <input v-model="form.heading" style="border: none;outline: none;height: 40px;width: 90%;
          border-radius: 3px;
          "
            placeholder="标题"></input>
        </div>
        <div style="margin-top: 15px;">
          <mavon-editor ref="mavonEditor"
          style="height: 380px;"
          @imgAdd="handleEditorImgAdd" @imgDel="imgDel" :toolbars="toolbars"
            :scrollStyle="true" :ishljs="true" @change="change" v-model="blogContent" codeStyle="atom-one-dark"
            :externalLink="externalLink" />
        </div>
        <div
        style="background-color: #ffffff;border-radius: 4px;padding: 10px;margin-top: 15px;"
        >
          <div style="margin-top:15px; border-radius: 3px;background-color: #FFFFFF;">
            <span style="font-size: 20px;display: inline-block;width: 9%;
            text-align: center;line-height: 40px;height: 40px;color: #333333;
            "><strong>标签</strong>
            </span>
            <el-select multiple collapse-tags v-model="form.labelIds" placeholder="请选择标签" style="
            width:91%;
            border:none;
            ">
              <div class="el-input" style="width:100%;">
                <input v-model="dropDownValue" placeholder="请输入" class="el-input__inner"
                  style="border: none;outline: none;" @keyup="dropDownSearch" />
              </div>
              <el-option style="display: none;" key="none" value="none" label="暂无数据">
              </el-option>

              <el-option v-for="labelItem in labelList" :key="labelItem.id" :label="labelItem.labelName"
                :value="labelItem.id"></el-option>
            </el-select>
          </div>
          <div style="margin-top:15px; border-radius: 3px;background-color: #FFFFFF;">
            <span style="font-size: 20px;display: inline-block;width: 9%;
            text-align: center;line-height: 40px;height: 40px;color: #333333;
            "> <strong>向他提问</strong></span>
            <el-select multiple collapse-tags v-model="form.toUsers" placeholder="请选择您要提问的人" style="
            width:91%;
            border:none;
            ">
              <div class="el-input" style="width:100%;">
                <input v-model="searchUser" placeholder="请输入" class="el-input__inner"
                  style="border: none;outline: none;" @keyup="dropDownSearchUser" />
              </div>
              <el-option style="display: none;" key="none" value="none" label="暂无数据">
              </el-option>

              <el-option v-for="item in userList" :key="item.username" :label="item.nickname"
                :value="item.username"></el-option>
            </el-select>
          </div>
            <el-button type="primary" @click="createQuiz" style="width: 100%;margin-top: 15px;">
              提问
            </el-button>
        </div>
      </el-col>
    </el-row>
    </CardBackground>
  </div>
</template>

<script>
import {
    publishArticleApi,
    getAllLabelApi,
    uploadImageApi,
    getDetailArticelByIdApi,
    updateArticleApi
  } from '@/api/article'
import Hot from '@/components/Hot.vue'
import {getAllUsernameApi} from '@/api/user'
import {createQuizApi} from '@/api/quiz'
import global from '@/api/global_variable.js'
import CardBackground from '@/components/ui/CardBackgroundUi'
  export default{
    components: {
      Hot,
      CardBackground
    },
    name:'quiz',
    created(){
      this.getAllUsername()
      this.getAllLabel()
    },
    data() {
      return {
        imgURL:global.imgURL,
        loading:false,
        searchUser:'',
        userList:[],
        userListAll:[],
        labelList: [],
        labelListAll: [],
        hotQuizList:[
          {
            title:"热门提问top01",
          },{
            title:"热门提问top02",
          },{
            title:"热门提问top03",
          }
        ],
        toolbars: {
          bold: true, // 粗体
          italic: true, // 斜体
          header: true, // 标题
          underline: true, // 下划线
          strikethrough: true, // 中划线
          mark: true, // 标记
          superscript: true, // 上角标
          subscript: true, // 下角标
          quote: true, // 引用
          ol: true, // 有序列表
          ul: true, // 无序列表
          link: true, // 链接
          imagelink: true, // 图片链接
          help: true, // 帮助
          code: true, // code
          table: true, // 表格
          subfield: true, // 是否需要分栏
          fullscreen: true, // 全屏编辑
          readmodel: true, // 沉浸式阅读
          htmlcode: true, // 展示html源码
          redo: true, // 下一步
          alignleft: true, // 左对齐
          aligncenter: true, // 居中
          alignright: true, // 右对齐
          /* 1.3.5 */
          undo: true, // 上一步
          trash: true, // 清空
          save: true, // 保存（触发events中的save事件）
          /* 1.4.2 */
          navigation: true // 导航目录
        },
        externalLink: {
          markdown_css: function() {
            // 这是你的markdown css文件路径
            return '/mavon-editor/markdown/github-markdown.min.css'
          },
          hljs_js: function() {
            // 这是你的hljs文件路径
            return '/mavon-editor/highlightjs/highlight.min.js'
          },
          hljs_css: function(css) {
            // 这是你的代码高亮配色文件路径
            return '/mavon-editor/highlightjs/styles/' + css + '.min.css'
          },
          hljs_lang: function(lang) {
            // 这是你的代码高亮语言解析路径
            return '/mavon-editor/highlightjs/languages/' + lang + '.min.js'
          },
          katex_css: function() {
            // 这是你的katex配色方案路径路径
            return '/mavon-editor/katex/katex.min.css'
          },
          katex_js: function() {
            // 这是你的katex.js路径
            return '/mavon-editor/katex/katex.min.js'
          }
        },
        blogContent: '',
        form: {
          id: '',
          heading: '',
          typeId: '',
          blog: '',
          labelIds: [],
          isDrafts: 0,
          codeStyle: '',
        },
        dropDownValue: '',
      }
    },
    methods: {
     async getAllUsername(){
       const res= await getAllUsernameApi()
        this.userList=res.data.data
        this.userListAll=res.data.data
      },
      dropDownSearch() {
        var _this = this;
        _this.labelList = _this.labelListAll.filter(item => item.labelName.includes(_this.dropDownValue));
      },
      dropDownSearchUser() {
        var _this = this;
        _this.userList = _this.userListAll.filter(item => item.nickname.includes(_this.searchUser));
      },
      codeStyleSearch() {
        var _this = this;
        _this.codeStyleList = _this.codeStyleListAll.filter(item => item.style.includes(_this.codeStyleValue));
      },
      async getAllLabel() {
        const res = await getAllLabelApi()
        this.labelList = res.data.data
        this.labelListAll = this.labelList
      },
      async createQuiz(){
        this.loading=true
        const res=await createQuizApi(this.form)
        if(res.data.success){
          this.$message({
            type: 'success',
            message: '成功发送一条提问'
          })
        }else{
          this.$message({
            type: 'warning',
            message: res.data.errorMsg
          })
        }
        this.loading=false
      },
      handleEditorImgAdd(pos, file) {
        var newdate = new FormData();
        newdate.append("file", file);
        uploadImageApi(newdate).then((res) => { //图片上传到服务器返回图片url 链接
          this.$refs.mavonEditor.$img2Url(pos,res.data.data); //通过ref插入到文档中
        });
      },
      change() {
        this.form.blog = this.$refs.mavonEditor.d_render
        this.form.blogMd = this.$refs.mavonEditor.d_value
      },
      imgDel() {

      },
    },

  }
</script>

<style scoped>
  .background_img{
    width: 100%;
    border:1px solid transparent;
    background:url('https://img3.wallspic.com/previews/1/7/0/0/30071/30071-ping_guo-duo_mei_ti-jia_ju-ta-dian_zi_she_bei-x750.jpg') no-repeat;
    background-position: 0px -350px;
    background-attachment: fixed;
    background-size: cover;
    padding-top: 50px;
    z-index: -2;
  }
  .background_img_mask{
    width:100%;
    height:100%;
    position: absolute;
    background-color: rgba(0,0,0,.3);
    margin-top:-50px;
    z-index: -1;
  }
</style>
