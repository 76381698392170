<template>

<div class="card"><slot></slot></div>
</template>

<script>
  export default{
    name:'CardHotUi'
  }
</script>

<style scoped>
  .card {
  background-color:#F5F5F5;
  }
</style>
