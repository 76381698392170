<template>
  <div>
    <CardHotUi>
      <ul style="
        margin: 0px;
        padding: 10px;
      ">
      <li
      style="
      font-size: 20px;
      padding: 5px;
      font-weight: bold;
      color: #333333;
      "
      >
        {{typeMap[type]}}
      </li>
        <li v-for="(item,index) in dataList"
        style="
        padding: 5px;"
        >
        <div
        style="color: #8A9FAB;
        text-decoration: none;
        font-size: 14px;
        cursor: pointer;
        "
        @click="toDetils(item.id)"
        >
           <span>
            {{item.heading}}
            </span>
            <span style="color: #FAC29E;">(赞{{item.praise}})</span>
          </div>
        </li>
      </ul>
    </CardHotUi>

  </div>
</template>

<script>
  import {getHotBlogApi,getHotQuizApi} from '@/api/article'
  import CardHotUi from './ui/CardHotUi'
  export default{
    name:'Hot',
    props: ["type"],
    components:{
      CardHotUi
    },
    created(){
      this.getDataList()
    },
    data() {
      return {
        dataList:[],
        typeMap:{
          hotBlog:'热门文章',
          hotQuiz:'热门提问'
        },
      }
    },
    methods: {
      toDetils(blogId){
        this.$router.push({path:'/details',query:{blogId:blogId}})
        this.$emit('changeDetails',blogId)
      },
      getDataList() {
        switch(this.type){
          case 'hotBlog':
            getHotBlogApi().then((res)=>{
              this.dataList=res.data.data
            })
            break
          case 'hotQuiz':
            getHotQuizApi().then((res)=>{
              this.dataList=res.data.data
            })
        }
      }
    },
  }
</script>

<style scoped>
  /* .card{
    margin: 0px;
    list-style: none;color: #FBFBFB;
    border-radius: 5px;
    background-color:rgba(0,0,0,0.5);
    padding: 10px;
  } */
  li{
    list-style: none;
  }
</style>
