<template>
<div class="card">
    <slot></slot>
</div>
</template>

<script>
  export default{
    name:'CardBackgroundUi'
  }
</script>

<style scoped>
.card{
  background-color: #E8E8E8;
  padding:15px;
}
</style>
