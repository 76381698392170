import request from '@/utils/request'
// export const createQuizApi = (data) => request.post('/public/blog/createQuiz', {useToken:true})
export function createQuizApi(data){
  return request({
    url:'/public/blog/createQuiz',
    method:'post',
    data,
    useToken:true,
    useIpAddress:true
  })
}
